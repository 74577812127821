<template>
  <div :key="componentKeyinteractions">
    <v-card
      id="patientformpanel"
      color="info"
    >
      <v-row :key="componentTopCardKey">
        <v-col
          cols="12"
          md="4"
          sm="4"
        >
          <v-card-text class="text-center">
            <v-card-title class="justify-left">
              Renew Prescription(s)
            </v-card-title>
            <h2>{{ patientProfile['FIRST NAME'] }} {{ patientProfile['LAST NAME'] }}</h2>
            <p>Gender: {{ patientProfile['GENDER'] }} || DOB: {{ this.$moment(this.patientProfile['DATE OF BIRTH']).format('YYYY-MM-DD') }} </p>
          </v-card-text>
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="4"
        >
          <v-card-text id="headerTable">
            <v-data-table
              :headers="headersCard"
              :items="selectedMedication"
              hide-default-footer
              dense
              item-key="RxNumber"
            >
              <template v-slot:item="props">
                <tr>
                  <td align="left">
                    {{ props.item['GENERIC NAME'] || props.item['GenericName'] }}
                  </td>
                  <td align="left">
                    {{ props.item['RxStrength'] || props.item['RxStrength'] }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="4"
        >
          <v-card-text class="text-center">
            <v-card-title class="justify-left"></v-card-title>
            <h2>Dr. {{ patientProfile['PRIMARY FIRST NAME'] }} {{ patientProfile['PRIMARY LAST NAME'] }}</h2>
            <p>Fax: {{ patientProfile['PRIMARY FAX #'] }} || Tel: {{ (this.patientProfile['PRIMARY PHONE #']) }} </p>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
    <v-stepper
      v-model="activeStep"
      class="custom-header"
    >
      <!-- Header -->
      <v-stepper-header>
        <!-- Header: Step 1 -->
        <v-stepper-step
          :complete="activeStep > 1"
          step="1"
        >
          <div class="d-flex align-center">
            <span class="text--primary text-4xl font-weight-bold me-3">01</span>
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold">Medications</span>
              <span class="text--secondary text-xs">Select Meds and Quantities</span>
            </div>
          </div>
        </v-stepper-step>

        <v-divider></v-divider>

        <!-- Header: Step 3 -->
        <v-stepper-step
          step="2"
          :complete="activeStep > 2"
        >
          <div class="d-flex align-center">
            <span class="text--primary text-4xl font-weight-bold me-3">02</span>
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold">Details</span>
              <span class="text--secondary text-xs">Lets Document the Renewal</span>
            </div>
          </div>
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3">
          <div class="d-flex align-center">
            <span class="text--primary text-4xl font-weight-bold me-3">03</span>
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold">Form Signoff</span>
              <span class="text--secondary text-xs">Form Submission</span>
            </div>
          </div>
        </v-stepper-step>
      </v-stepper-header>

      <!-- Stepper Items -->
      <v-stepper-items>
        <!-- Stepper Content: Step 1 -->

        <v-stepper-content step="1">
          <div class="tw-card tw-shadow-lg tw-bg-gray-50">
            <div class="tw-card-body">
              <!-- Selected Meds -->

              <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 gap-4">
                <div>
                  <h2 class="tw-pt-6 tw-card-title">
                    A. Total Quantity Authorized
                  </h2>
                  <v-list
                    id="radioList"
                    class="tw-bg-gray-50"
                    dense
                    subheader
                  >
                    <v-radio-group
                      v-model="patientProfile.radioSelection"
                      class="tw-bg-gray-50"
                      :mandatory="false"
                    >
                      <v-list-item
                        class="tw-bg-gray-50"
                      >
                        <v-list-item-action>
                          <v-radio
                            value="radio1"
                            @click="radioSelectionRadio1()"
                          ></v-radio>
                        </v-list-item-action>
                        <v-list-item-content @click="radioSelectionRadio1()">
                          <v-list-item-subtitle class="subtitle-2">
                            Maximum quantity as per OCP practice Guidelines
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <!-- <v-list-item>
                    <v-list-item-action>
                      <v-radio
                        value="radio2"
                        @click="radioSelectionRadio2()"
                      ></v-radio>
                    </v-list-item-action>
                    <v-list-item-content @click="radioSelectionRadio2()">
                      <v-list-item-subtitle class="subtitle-2">
                        A six month's supply
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item> -->
                      <v-list-item>
                        <v-list-item-action>
                          <v-radio
                            value="radio3"
                            @click="radioSelectionRadio3()"
                          ></v-radio>
                        </v-list-item-action>
                        <v-list-item-content @click="radioSelectionRadio3()">
                          <v-list-item-subtitle class="subtitle-2">
                            Pharmacist Defined Quantity
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-radio-group>
                  </v-list>
                </div>
                <div
                  v-if="this.patientProfile.radioSelection === 'radio1'"

                  class="tw-p-8  tw-border-4 tw-border-blue-500 tw-border-opacity-100"
                >
                  <h2 class="tw-card-title">
                    B. Prescription Days Supply
                  </h2>
                  <v-combobox
                    v-model="patientProfile['DISPENSE']"
                    label="# of Days to Dispense (Select or Type #)"
                    :items="daysSupply"
                    hide-details
                    :autofocus="autofocus"
                  >
                    >
                  </v-combobox>
                </div>
              </div>
            </div>
          </div>
          <div

            class="tw-card tw-shadow-lg tw-bg-gray-50 tw-pt-2 tw-mt-6"
          >
            <div class="tw-card-body">
              <div
                id="medListId"
                :key="componentKeyMedList"
                class="tw-card tw-col-span-1 tw-row-span-3 tw-shadow-lg xl:tw-col-span-2 tw-bg-base-100"
              >
                <single-medication-selection-repeat
                  v-if="this.patientProfile['DISPENSE'] && this.patientProfile.radioSelection === 'radio1'"
                  :selected="selectedMedication"
                  @medicationupdatelist="selected"
                >
                </single-medication-selection-repeat>
                <single-medication-selection
                  v-if="this.patientProfile.radioSelection === 'radio3'"
                  :selected="selectedMedication"
                  @medicationupdatelist="selected"
                >
                </single-medication-selection>
              </div>
            </div>
          </div>
        </v-stepper-content>

        <!-- Stepper Content: Step 2 -->

        <v-stepper-content step="2">
          <v-expansion-panels v-model="panel">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span class="text-base">Renewal Considerations</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-form class="multi-col-validation">
                  <v-row>
                    <v-col
                      cols="12"
                      md="3"
                    >
                      <div id="checkallcheckbox">
                        <v-list-item>
                          <v-list-item-content>
                            <v-btn
                              rounded
                              color="primary"
                              @click="checkAllSecondary()"
                            >
                              Check All
                              <v-icon
                                dark
                                right
                              >
                                {{ icons.mdiCheckboxMarkedCircleOutline }}
                              </v-icon>
                            </v-btn>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </v-col>

                    <v-col
                      cols="12"
                      md="9"
                    >
                      <v-list>
                        <v-list-item>
                          <v-list-item-action>
                            <v-checkbox
                              v-model="patientProfile.optionalCheck1"
                              true-value="yes"
                            ></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content @click="wordClickToggle('optionalCheck1')">
                            Drug is safe and effective considering: History, symptoms, precautions, potential risks and benefits
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-action>
                            <v-checkbox
                              v-model="patientProfile.requiredCheck1"
                              true-value="yes"
                            ></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content @click="wordClickToggle('requiredCheck1')">
                            Benefits outweigh the risks to the patient requiring adapting or renewing the prescription
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-action>
                            <v-checkbox
                              v-model="patientProfile.requiredCheck2"
                              true-value="yes"
                            ></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content @click="wordClickToggle('requiredCheck2')">
                            Prescription renewal or adaptation is in the best interest of the patient
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item three-line>
                          <v-list-item-action>
                            <v-checkbox
                              v-model="patientProfile.requiredCheck3"
                              true-value="yes"
                            ></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content @click="wordClickToggle('requiredCheck3')">
                            Pharmacy is in possession of the existing order or prescription to be renewed or adapted, or has access to the information contained in the original prescription (copy, verbal confirmation from the pharmacy, or medical record)
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-action>
                            <v-checkbox
                              v-model="patientProfile.requiredCheck4"
                              true-value="yes"
                            ></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content @click="wordClickToggle('requiredCheck4')">
                            The medication to be continued is for the previously diagnosed chronic and long-term condition
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-action>
                            <v-checkbox
                              v-model="patientProfile.requiredCheck5"
                              true-value="yes"
                            ></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content @click="wordClickToggle('requiredCheck5')">
                            The patient has tolerated the medication without serious side effects
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-action>
                            <v-checkbox
                              v-model="patientProfile.requiredCheck6"
                              true-value="yes"
                            ></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content @click="wordClickToggle('requiredCheck6')">
                            Confirmation that informed consent was received
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-form>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- Adaptaion Details -->
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span class="text-base">Doctor Information: Dr. {{ patientProfile['PRIMARY FIRST NAME'] }} {{ patientProfile['PRIMARY LAST NAME'] }}</span>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-container column>
                  <v-row
                    wrap
                    ml-4
                  >
                    <v-col
                      cols="auto"
                      xs="12"
                      sm="7"
                      md="3"
                      justify-start
                    >
                      <doctor-search :refresh-num="refresh"></doctor-search>
                      <v-btn
                        id="v-step-4"
                        color="primary"
                        primary
                        class="my-3"
                        @click.native="openDrOverlay"
                      >
                        Select a Doctor
                      </v-btn>
                    </v-col>
                    <v-col
                      cols="auto"
                      xs="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="patientProfile['PRIMARY FIRST NAME']"
                        label="Doctor First Name"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="auto"
                      xs="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="patientProfile['PRIMARY LAST NAME']"
                        label="Doctor Last Name"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row
                    wrap
                    ml-4
                  >
                    <v-col
                      cols="auto"
                      xs="12"
                      sm="7"
                      md="7"
                      justify-start
                    >
                      <v-list ubheader>
                        <v-list-item>
                          <v-list-item-action>
                            <v-checkbox
                              v-model="patientProfile.doctorNotified"
                              true-value="yes"
                            ></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content @click="wordClickToggle('doctorNotified')">
                            <v-list-item-subtitle class="subtitle-2">
                              Has the doctor been notified?
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-stepper-content>

        <!-- Stepper Content: Step3 -->
        <v-stepper-content step="3">
          <v-expansion-panels v-model="finalpanel">
            <v-expansion-panel>
              <!-- <v-expansion-panel-header>
                <span class="text-base">Pharmacist Signature <v-icon
                  color="warning"
                  medium
                >
                  {{ icons.mdiAlert }}
                </v-icon></span>
              </v-expansion-panel-header> -->

              <v-expansion-panel-content>
                <pg-sig-input
                  :key="signatureCompKey"
                  @save="saveSignature"
                  @updatePharmacist="updatePharmacist"
                ></pg-sig-input>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                <span class="text-base">Patient Information: {{ patientProfile['LAST NAME'] }}, {{ patientProfile['FIRST NAME'] }} </span>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    sm="6"
                  >
                    <v-text-field
                      v-model="patientProfile['FIRST NAME']"
                      label="First Name"
                      type="text"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    sm="6"
                  >
                    <v-text-field
                      v-model="patientProfile['LAST NAME']"
                      label="Last Name"
                      type="text"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- <v-expansion-panel>
              <v-expansion-panel-header>
                <span class="text-base">Medication: {{ patientProfile['BRANDNAME'] }} {{ patientProfile['RXSTRENGTH'] }}</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card id="rxinfoid">
                  <v-card-text>
                    <template>
                      <v-form class="multi-col-validation">
                        <v-row>
                          <v-col
                            cols="12"
                            md="6"
                            sm="6"
                          >
                            <v-text-field
                              v-model="patientProfile['RXNUMBER']"
                              label="RX Number"
                              type="text"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                            sm="6"
                          >
                            <v-text-field
                              v-model="patientProfile['RXDATE']"
                              label="RX Date"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            md="6"
                            sm="6"
                          >
                            <v-text-field
                              v-model="patientProfile['BRANDNAME']"
                              label="Brand Name"
                              type="text"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                            sm="6"
                          >
                            <v-text-field
                              v-model="patientProfile['GENERICNAME']"
                              label="Generic Name"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col
                            cols="12"
                            md="6"
                            sm="6"
                          >
                            <v-text-field
                              v-model="patientProfile['DAYS']"
                              label="Day Supply"
                              type="text"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                            sm="6"
                          >
                            <v-text-field
                              v-model="patientProfile['RXQUANTITYDISPENSE']"
                              label="Quantity"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            md="6"
                            sm="6"
                          >
                            <v-text-field
                              v-model="patientProfile['RXSTRENGTH']"
                              label="Drug Strength"
                              type="text"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                            sm="6"
                          >
                            <v-text-field
                              v-model="patientProfile['SIG']"
                              label="SIG"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </template>
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel> -->

            <v-expansion-panel>
              <v-expansion-panel-header>
                <span class="text-base">Pharmacist Information: {{ patientProfile['rphfirstname'] }} </span>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-container>
                  <v-row
                    wrap
                    ml-4
                  >
                    <v-col
                      cols="auto"
                      xs="12"
                      sm="12"
                      md="4"
                    >
                      <pharmacist-search :key="refresh"></pharmacist-search>
                      <v-btn
                        id="v-step-5"
                        color="primary"
                        primary
                        class="my-3"
                        @click.native="openPharmacistOverlay"
                      >
                        Select a Pharmacist
                      </v-btn>
                    </v-col>
                    <v-col
                      cols="auto"
                      xs="12"
                      sm="6"
                      md="3"
                    >
                      <v-text-field
                        v-model="patientProfile['rphfirstname']"
                        label="Pharmacist Name"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="auto"
                      xs="12"
                      sm="6"
                      md="3"
                    >
                      <v-text-field
                        v-model="patientProfile['ocp']"
                        label="OCP#"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-stepper-content>
      </v-stepper-items>
      <div

        class="d-flex justify-center align-center"
      >
        <v-card-actions>
          <pg-form-buttons
            v-if="activeStep >= 1 && (this.patientProfile.radioSelection === 'radio1' || this.patientProfile.radioSelection === 'radio3')"
            :step="activeStep"
            :final-step="3"
            @next="next"
            @previous="previous"
          ></pg-form-buttons>
          <!-- <pg-form-buttons v-show="activeStep > 1 && (this.patientProfile.radioSelection === 'radio1' || this.patientProfile.radioSelection === 'radio3')" @next="next" @previous="previous" :step="activeStep" :finalStep="3"></pg-form-buttons> -->
          <!-- <v-col>
            <v-btn
              v-show="activeStep === 1 && (this.patientProfile.radioSelection === 'radio1' || this.patientProfile.radioSelection === 'radio3')"
              color="success"
              large
              @click="nextstepone"
            >
              Next
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              v-show="activeStep === 2"
              color="warning"
              large
              @click="activeStep = 1"
            >
              Previous
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              v-show="activeStep === 3"
              color="warning"
              large
              @click="activeStep = 2"
            >
              Previous
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              v-show="activeStep === 2"
              color="success"
              large
              @click="nextstep"
            >
              Next
            </v-btn>
          </v-col> -->
          <!-- <v-col> -->
          <pg-submit
            v-show="activeStep === 3"
            id="v-step-7"
            form-type="renewingRx"
            base-filename="renewingRx"
            type-of-medscheck="typeOfMedscheck"
            :patient-data-decorator="ProcessMeds"
            :patient-data="patientProfile"
            @saveInfo="savePdfInfo($event)"
          >
            Submit
          </pg-submit>
          <save-patient-state
            :patient-profile="patientProfile"
            :label="saveMedscheck"
            @onload="OnLoad($event)"
            @click="statekeyRender"
          ></save-patient-state>
          <!-- </v-col> -->
        </v-card-actions>
      </div>
    </v-stepper>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import AppCardActions from '@core/components/app-card-actions/AppCardActions.vue'
import { mdiAlert, mdiCheckboxMarkedCircleOutline } from '@mdi/js'
import pgSigInput from '@/components/common/ui/Form/pgSigInput/pgSigInput.vue'
import submitPdf from '@/components/common/ui/Form/Submit/ProcessPDF.vue'
import Container from '@/components/common/ui/Layout/Container/Container.vue'
import Section from '@/components/common/ui/Layout/Section/Section.vue'
import PatientPanel from '@/views/patient/components/PatientPanel.vue'
import PharmacistSearch from '@/components/common/ui/Form/PharmacistSearch/PharmacistSearch.vue'
import DoctorSearch from '@/components/common/ui/Form/DoctorSearch/DoctorSearch.vue'
import { stateService } from '@/render/api/zarya'
import SavePatientState from '@/views/pages/tools/SaveDocumentState.vue'
import serverFormProcess from '@/views/Medscheck/Utils/processFormDataMedicationSelection'
import SingleMedicationsSelection from '@/components/phoxTables/drug/multiDrugRepeat.vue'
import SingleMedicationsSelectionRepeat from '@/components/phoxTables/drug/multiDrug.vue'
import pgFormButtons from '@/views/patient/components/pg-form-buttons.vue'

// import SingleMedicationsSelection from '../rxSearch/MedicationsSelection.vue'

export default {
  name: 'RenewingRx',
  components: {
    'single-medication-selection': SingleMedicationsSelection,
    'pg-submit': submitPdf,
    'save-patient-state': SavePatientState,
    'pharmacist-search': PharmacistSearch,
    'doctor-search': DoctorSearch,
    'pg-section': Section,
    'pg-container': Container,
    'app-card-actions': AppCardActions,
    'patient-panel': PatientPanel,
    'single-medication-selection-repeat': SingleMedicationsSelectionRepeat,
    'pg-sig-input': pgSigInput,
    'pg-form-buttons': pgFormButtons,
  },
  props: [], // possible will include selected meds from the patient profile view (saved state)
  data() {
    this.$store.dispatch('setFormClass', 'renewingRx')

    return {
      autofocus: true,
      saveMedscheck: 'Save Progress',
      icons: { mdiAlert, mdiCheckboxMarkedCircleOutline },
      panel: 0,
      finalpanel: 0,
      initial: '',
      canvaslocal: '',
      componentTopCardKey: 0,
      signatureCompKey: 0,
      refresh: true,
      checkAll: false,
      adaptionChoice: '',
      adaptationChoices: ['Dose Change', 'Dosage Form', 'Regimen', 'ROA'],
      requiredCheckContent: [
        'requiredCheck1',
        'requiredCheck2',
        'requiredCheck3',
        'requiredCheck4',
        'optionalCheck1',
        'requiredCheck5',
        'requiredCheck6',
      ],
      writtenResponses: [
        'doseReason',
        'originalDose',
        'newDose',
        'doseFormReason',
        'regimenReason',
        'originalRoute',
        'adaptedRoute',
        'roaReason',
      ],
      doseBoxes: ['doseIncrease', 'doseDecrease'],
      adaptionChoiceBoxes: ['Dose Change Box', 'Dosage Form Box', 'Regimen Box', 'ROA Box'],
      patientProfile: {},
      loadingPatient: false,
      activeStep: 1,
      rphRepeat: '',
      rphQuantity: '',
      TotalAuthorizedkey: 0,
      componentKeyRefreshMedList: 0,
      componentKeyinteractions: 0,
      componentKeyMedList: 0,
      submitDisabled: true,
      patientData: {},
      medicationsList: [],
      selectedMedication: [],
      headers: [
        {
          text: 'Rx Number',
          align: 'left',
          sortable: false,
          value: 'RxNumber',
        },
        {
          text: 'Generic Name',
          align: 'left',
          sortable: false,
          value: 'GenericName',
        },
        {
          text: 'Strength',
          align: 'left',
          sortable: false,
          value: 'RxStrength',
        },
        {
          text: 'SIG',
          align: 'left',
          sortable: false,
          value: 'SIG',
        },
        {
          text: 'Quantity Dispensed',
          align: 'left',
          sortable: false,
          value: 'QtyDispense',
        },
        {
          text: 'Days Supply',
          align: 'left',
          sortable: false,
          value: 'Days',
        },
        {
          text: 'Total Authorized',
          align: 'left',
          sortable: false,
          value: 'TotalAuthorized',
        },
        {
          text: '6 Months Supply',
          align: 'left',
          sortable: false,
          value: 'sixMonths',
        },
        {
          text: 'Delete from List',
          align: 'left',
          sortable: false,
        },
      ],

      headersCard: [
        {
          text: 'Generic Name',
          align: 'left',
          sortable: false,
          value: 'GenericName',
        },
        {
          text: 'Strength',
          align: 'left',
          sortable: false,
          value: 'RxStrength',
        },
      ],
      daysSupply: [30, 60, 90],
      selectedOption: '',
    }
  },
  computed: {
    ...mapGetters(['FormClass', 'UserProfile', 'PharmacyProfile']),
    ...mapState(['Patient', 'User']),
  },
  mounted() {
    if (this.$store.state.Patient.data.stateId) {
      if (this.$store.state.Patient.data.PatientSignature.signatureData) {
        this.patientProfile = {
          PatientSignature: {},
          PharmacistSignature: {},
        }
        this.selectedLoadPatient()
      } else {
        this.selectedLoadPatientNoSignature()
      }
    }
    this.preloadForm()
    this.selectedPatient()
    this.currentDate()
    this.currentDateTime()

    // if selectedMedications is full
    if (this.$store.state.Patient.selectedMedications.length) {
      this.selectedMedication = this.$store.state.Patient.selectedMedications
    }

    // window.addEventListener('resize', this.resizeCanvas)
    // this.resizeCanvas()
    // FIX ME need to fix this damn unused patient ID
    this.$root.$on('Refresh_Table', patientID => {
      this.preloadForm()
      this.selectedPatient()
      this.panel = 0
      this.finalpanel = 0

      // this.componentKeyinteractions += 1
      // window.addEventListener('resize', this.resizeCanvas)
      // this.resizeCanvas()
    })
  },
  methods: {
    previous() {
      this.activeStep--
      if (this.activeStep === 2) {
        this.patientProfile.rphfirstname = `${this.$store.state.User.user.firstName} ${this.$store.state.User.user.lastName}`
        this.patientProfile.ocp = `${this.$store.state.User.user.ocp}`
        this.signatureCompKey++
      }
    },
    next() {
      this.activeStep++
    },
    updatePharmacist({ pharmacistName, ocp }) {
      this.patientProfile.rphfirstname = pharmacistName
      this.patientProfile.ocp = ocp
    },
    saveSignature({ pharmacistSig, signatureDate }) {
      this.patientProfile.PharmacistSignature = pharmacistSig
      this.patientProfile.rphsignature = pharmacistSig
      this.patientProfile.DATESIGNED = signatureDate
    },
    deleteMedFromList(index) {
      if (window.confirm('Are you sure you want to remove this medication from the list?')) {
        this.selectedMedication.splice(index, 1)
      }
    },
    radioSelectionRadio1() {
      this.patientProfile.radioSelection = 'radio1'
      this.ProcessMeds(this.patientProfile)
      this.componentKeyMedList += 1

      // this.patientProfile['TOTAL QUANTITY AUTHORIZED'] = this.patientProfile.selectedMedication[0].TotalAuthorized
      this.TotalAuthorizedkey += 1

      // this.panel = 2
    },
    radioSelectionRadio2() {
      this.patientProfile.radioSelection = 'radio2'

      // this.patientProfile['TOTAL QUANTITY AUTHORIZED'] = Math.round(
      //   (this.patientProfile.selectedMedication[0].QtyDispense / this.patientProfile.selectedMedication[0].Days) * 180,
      // )
      this.TotalAuthorizedkey += 1

      // this.panel = 2
    },
    radioSelectionRadio3() {
      this.patientProfile.radioSelection = 'radio3'

      // this.patientProfile['TOTAL QUANTITY AUTHORIZED'] = Math.round(
      //   (this.patientProfile.selectedMedication[0].QtyDispense / this.patientProfile.selectedMedication[0].Days) * 180,
      // )
      this.TotalAuthorizedkey += 1
      this.componentKeyMedList += 1

      // this.panel = 2
    },
    currentDate() {
      const current = new Date()
      const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`
      const dateyy = `${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()}`

      this.patientProfile.DATESIGNED = dateyy
      this.patientData.DATESIGNED = dateyy

      return date
    },
    currentDateTime() {
      const current = new Date()
      const date = `${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()}`
      const time = `${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`
      const dateTime = `${date} ${time}`

      return dateTime
    },
    statekeyRender() {
      this.componentKeyinteractions += 1
    },
    nextstepone() {
      this.activeStep = 2
      this.refreshSignatureStatus += 1
      this.ProcessMeds(this.patientProfile)
    },
    nextstep() {
      this.activeStep = 3
      this.refreshSignatureStatus += 1
    },
    recapture() {
      this.initial = false
      this.refreshSignatureStatus += 1
    },
    preloadForm() {
      this.patientData = {
        ...this.$store.state.Patient.data,
        PharmacistSignature: '',
        optionalCheck1: false,
        requiredCheck1: false,
        requiredCheck2: false,
        requiredCheck3: false,
        requiredCheck4: false,
        requiredCheck5: false,
        requiredCheck6: false,
        doseIncrease: false,
        doseDecrease: false,
        rphfirstname: `${this.$store.state.User.user.firstName} ${this.$store.state.User.user.lastName}`,
        ocp: this.$store.state.User.user.ocp,
        doctorNotified: false,
        pharmacyFullAdd: `${this.$store.state.User.pharmacy.address.streetNumber} ${this.$store.state.User.pharmacy.address.streetName}, ${this.$store.state.User.pharmacy.address.city} ${this.$store.state.User.pharmacy.address.state} ${this.$store.state.User.pharmacy.address.code}`,
      }
      this.medicationsList = this.$store.state.Patient.medications
      this.patientProfile = {
        ...this.$store.state.Patient.data,
        PharmacistSignature: '',
        optionalCheck1: false,
        requiredCheck1: false,
        requiredCheck2: false,
        requiredCheck3: false,
        requiredCheck4: false,
        requiredCheck5: false,
        requiredCheck6: false,
        doseIncrease: false,
        doseDecrease: false,
        rphfirstname: `${this.$store.state.User.user.firstName} ${this.$store.state.User.user.lastName}`,
        ocp: this.$store.state.User.user.ocp,
        doctorNotified: false,
        pharmacyFullAdd: `${this.$store.state.User.pharmacy.address.streetNumber} ${this.$store.state.User.pharmacy.address.streetName}, ${this.$store.state.User.pharmacy.address.city} ${this.$store.state.User.pharmacy.address.state} ${this.$store.state.User.pharmacy.address.code}`,
      }
    },
    selectedPatient() {
      this.activeStep = 1
      this.componentTopCardKey += 1
      this.componentKeyinteractions += 1
      this.componentKeyMedList += 1

      // this.patientProfile = {
      //   ...this.$store.state.Patient.data,
      //   PatientSignature: {},
      //   PharmacistSignature: {},
      // }
    },
    selectedLoadPatient() {
      this.activeStep = 1
      this.componentTopCardKey += 1
      this.componentKeyinteractions += 1
      this.componentKeyMedList += 1
      this.patientProfile = {
        ...this.$store.state.Patient.data,
      }
    },
    selectedLoadPatientNoSignature() {
      this.activeStep = 1
      this.componentTopCardKey += 1
      this.componentKeyinteractions += 1
      this.componentKeyMedList += 1
      this.patientProfile = {
        ...this.$store.state.Patient.data,
        PatientSignature: {},
        PharmacistSignature: {},
      }
    },
    selected(selectedMedication) {
      this.selectedMedication = selectedMedication
      this.patientProfile = {
        ...this.patientProfile,
        patientdateofbirth: this.$moment(this.patientProfile['DATE OF BIRTH']).format('YYYY-MM-DD'),

        // RXNUMBER: this.selectedMedication[0].RxNumber,
        // RXSTRENGTH: this.selectedMedication[0].RxStrength,
        // RXDATE: this.$moment(this.selectedMedication[0].RxDate).format('YYYY-MM-DD'),
        // BRANDNAME: this.selectedMedication[0].BrandName,
        // GENERICNAME: this.selectedMedication[0].GenericName,
        // DAYS: this.selectedMedication[0].Days,
        // SIG: this.selectedMedication[0].SIGFull,
        // RXQUANTITYDISPENSE: this.selectedMedication[0].RxQtyDispense,
        // DIN: this.selectedMedication[0].DIN,
        rphfirstname: `${this.$store.state.User.user.firstName} ${this.$store.state.User.user.lastName}`,
        ocp: this.$store.state.User.user.ocp,
        selectedMedication: this.selectedMedication,

        // selectedMedicationQtytoDispense: this.selectedMedication.oneMonth * this.selectedOption.value,
      }

      // this.activeStep = 2
    },
    medicationsForPDF(patientData) {
      if (this.patientProfile.radioSelection === 'radio1') {
        this.selectedMedication.forEach((med, idx) => {
          if (med.TotalAuthorized / med.oneDay > 365) {
            med.TotalAuthorized = 365 * med.oneDay

            // console.log('med.TotalAuthorized', med.TotalAuthorized)
          } else {
            med.TotalAuthorized = med.TotalAuthorized

            // console.log('med.TotalAuthorized', med.TotalAuthorized)
          }
          patientData = {
            ...patientData,
            [`rxNumbers${idx + 1}`]: `${med['RX NUMBER'] || med.RxNumber} - ${
              med['GENERIC NAME'] || med.GenericName
            } - ${med.STRENGTH || med.RxStrength} - Qty: ${med.oneDay * this.patientProfile.DISPENSE} - Qty Auth:${
              med.TotalAuthorized
            }`,
          }
        })
      } else if (this.patientProfile.radioSelection === 'radio2') {
        this.selectedMedication.forEach((med, idx) => {
          patientData = {
            ...patientData,
            [`rxNumbers${idx + 1}`]: `${med['RX NUMBER'] || med.RxNumber} - ${
              med['GENERIC NAME'] || med.GenericName
            } - ${med.STRENGTH || med.RxStrength} - Qty: ${med.oneDay * this.patientProfile.DISPENSE} - Qty Auth:  ${
              med.sixMonth
            }`,
          }
        })
      } else if (this.patientProfile.radioSelection === 'radio3') {
        this.selectedMedication.forEach((med, idx) => {
          patientData = {
            ...patientData,
            [`rxNumbers${idx + 1}`]: `${med['RX NUMBER'] || med.RxNumber} - ${
              med['GENERIC NAME'] || med.GenericName
            } - ${med.STRENGTH || med.RxStrength} - Qty: ${med.rphQuantity} - Rep:  ${med.rphRepeat}`,
          }
        })
      }

      return patientData
    },
    ProcessMeds(patientData) {
      return serverFormProcess(this.selectedMedication, this.medicationsForPDF(patientData))
    },
    async downloadPDF(pdfRef) {
      const token = await this.$auth.getTokenSilently()
      this.$store.dispatch('pdfDownload', {
        token,
        id: pdfRef.id,
        filename: `${pdfRef.id}-${this.PatientProfile['FIRST NAME'].toLowerCase()}-${this.PatientProfile[
          'LAST NAME'
        ].toLowerCase()}`,
      })
    },
    savePdfInfo(data) {
      this.patientProfile.pdfRef = {
        ...data,
      }
      this.updateStateStatus()
    },
    async updateStateStatus() {
      this.saveDateSubmitted()
      let res
      const data = {
        formType: this.FormClass,
        state: this.patientProfile,
        patientID: this.patientProfile.PatientID,
        userID: this.UserProfile._id,
        pharmacyID: this.PharmacyProfile._id,
      }

      // this.$router.push('/patient/patientdashboard')
      this.$router.push({
        name: 'dashboard-document',
        query: {
          tab: 0,
        },
      })
      const token = await this.$auth.getTokenSilently()
      try {
        if (!this.patientProfile.stateId) {
          // create it and make its status complete (this is in case they complete a medscheck and it doesnt have a stateid already -- not working )
          // eslint-disable-next-line no-undef
          res = await stateService.createState(token, {
            ...data,
          })
          this.patientProfile.stateId = res.state._id
        }

        // eslint-disable-next-line no-undef
        res = await stateService.updateState(token, this.patientProfile.stateId, {
          ...data,
          status: 'complete',
        })

        return res
      } catch (err) {
        console.log(err)
      }

      // update it - once medcheck is complete and it has state id, change to complete -- works
    },

    // to be checked
    openOverlayRxSearch(searchIndex) {
      window.searchRx.open(medication => {
        this.patientProfile = {
          ...this.patientProfile,
          RXNUMBER: medication.RxNumber,
          RXSTRENGTH: medication.RxStrength,
          RXDATE: this.$moment(medication.RxDate).format('YYYY-MM-DD'),
          BRANDNAME: medication.BrandName,
          GENERICNAME: medication.GenericName,
          RXQUANTITYDISPENSE: medication.RxQtyDispense,
          DAYS: medication.Days,
          SIG: medication.SIGFull,
        }
        this.patientProfile['PRIMARY FIRST NAME'] = medication.FirstName
        this.patientProfile['PRIMARY LAST NAME'] = medication.LastName
      })

      this.submitDisabled = this.patientProfile.RXNUMBER
    },
    dosageToggle(direction) {
      if (direction === 'increase') {
        this.patientProfile.doseIncrease = 'yes'
        this.patientProfile.doseDecrease = false
      } else {
        this.patientProfile.doseDecrease = 'yes'
        this.patientProfile.doseIncrease = false
      }
    },
    OnLoad(state) {
      let selectedMedication = []
      if (state.selectedMedication) {
        selectedMedication = state.selectedMedication

        // second selection
        this.medicationSubStep = 1

        // delete state.selectedMedication;
      }

      this.patientProfile = {
        ...this.patientProfile,
        ...state,
        selectedMedication,
      }

      // this.$store.dispatch('updatePatientData', this.patientProfile)
      this.refresh += 1
    },
    saveDateSubmitted() {
      const current = new Date()
      const date = `${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()}`
      const time = `${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`
      const dateTime = `${date} ${time}`
      this.patientProfile.dateSubmitted = dateTime
    },
    wordClickToggle(patientTag) {
      this.patientProfile[patientTag] = !this.patientProfile[patientTag]
      if (this.patientProfile[patientTag] && this.patientProfile[patientTag] !== 'yes') {
        this.patientProfile[patientTag] = 'yes'
      }
    },
    adaptationChoiceToggler() {
      this.consistancyKeeper(this.doseBoxes, false)
      this.consistancyKeeper(this.writtenResponses, '')
      this.consistancyKeeper(this.adaptionChoiceBoxes, false)
      this.patientProfile[`${this.adaptionChoice} Box`] = 'yes'
    },
    checkAllSecondary() {
      if (this.checkAll === true || this.checkAll === 'yes') {
        this.checkAll = false
        this.panel = 1
      } else {
        this.checkAll = 'yes'
        this.panel = 1
      }
      this.consistancyKeeper(this.requiredCheckContent, this.checkAll)
      this.panel = 1
    },
    openPharmacistOverlay() {
      window.searchPharmacist.open(({ PharmID, UserName }) => {
        this.patientProfile = Object.assign(this.patientProfile, {
          rphfirstname: UserName,
          ocp: PharmID,
        })
      })
    },
    openDrOverlay() {
      window.searchDoctor.open(doctor => {
        this.patientProfile = Object.assign(this.patientProfile, {
          'PRIMARY FIRST NAME': doctor.DoctorFirstName,
          'PRIMARY LAST NAME': doctor.DoctorLastName,
        })
      })
    },
    consistancyKeeper(arr, consistantValue) {
      this.patientProfile = {
        ...this.patientProfile,
        ...arr.reduce(
          (acc, key) => ({
            ...acc,
            [key]: consistantValue,
          }),
          {},
        ),
      }
    },

    // https://developer.mozilla.org/en-US/docs/Web/API/Window/devicePixelRatio
    // resizeCanvas() {
    //   const ratio = Math.max(window.devicePixelRatio || 1, 1)
    //   const canvas = this.canvaslocal
    //   canvas.width = canvas.offsetWidth * ratio
    //   canvas.height = canvas.offsetHeight * ratio
    //   canvas.getContext('2d').scale(ratio, ratio)
    //   this.$refs.signaturePad.clearSignature() // otherwise isEmpty() might return incorrect value
    // },
  },
}
</script>

<style lang="scss" scoped>
#patientformpanel {
  div.v-card__title.justify-left {
    color: rgb(248, 248, 248) !important;
  }
  div.row {
    color: rgb(248, 248, 248) !important;
  }
  div.row div:nth-child(1) div h2 {
    color: rgb(248, 248, 248) !important;
  }
  div.row div:nth-child(2) div h2 {
    color: rgb(248, 248, 248) !important;
  }
  div.row div:nth-child(3) div h2 {
    color: rgb(248, 248, 248) !important;
  }
}
// #headersTable {
//   .theme--dark.v-data-table {
//     background-color: #95a6df80 !important;
//     color: #ffffff !important;
//   }
//   .theme--dark.v-data-table td {
//     color: rgb(255 255 255) !important;
//   }
// }
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #000000, #000000);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
.buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 8px;
}
.Signaturecontainer {
  width: '100%';
  padding: 8px 16px;
}
#radioList {
  background-color: #f9fafb !important;
  color: #ffffff !important;
}

// #checkallcheckbox {
//   border: double 3px transparent;
//   border-radius: 5px;
//   background-image: linear-gradient(white, white), radial-gradient(circle at top left, #000000, #000000);
//   background-origin: border-box;
//   background-clip: content-box, border-box;
// }
</style>
